.page-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (max-width: 576px) {
  .page-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
